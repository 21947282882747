.login-navigation {
  @apply w-[660px] bg-transparent;
}

.login-nav-button {
  @apply w-full h-[166px] rounded-[36px] font-medium text-3xl;
}
.login-nav-button-wrapper {
  @apply w-full rounded-[36px] mb-10;
}

.login-form {
  @apply relative w-[744px] h-fit p-8 flex flex-col items-start justify-center bg-primary-600 rounded-2xl;
}

.login-header {
  @apply absolute w-max -top-[110px] left-0 text-start text-5xl text-white font-normal pb-6;
}

.login-input {
  @apply w-full h-[72px] pl-[10px] rounded-[8px] text-white;
}
.login-input-wrapper {
  @apply w-full rounded-[8px] mb-10;
}

.login-submit {
  @apply w-fit h-[53px] px-4 flex items-center rounded-[36px] bg-primary-800;
}
.login-button-wrapper {
  @apply rounded-[36px] w-fit;
}

.qr-code-wrapper {
  @apply w-[396px] h-[396px] p-9 bg-white rounded-2xl;
}
