.home-page {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #1b2237 0%, #35446e 99.99%, #0e121d 100%);
  .home-banner-filter {
    background: rgba(#1f2937, 0.5);
  }
  .home-banner-filter-second {
    background: linear-gradient(
      35deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }
  .pixel-stream-card-filter {
    background: linear-gradient(180deg, #3f444d 0%, #131a25 100%);
    opacity: 30%;
  }
  .home-banner-image {
    width: 100%;
    object-fit: cover;
    height: 544px;
  }
  .offer-image {
    img {
      object-fit: cover;
      width: 100%;
      border-radius: 8px;
    }
  }
  .offer-glass {
    background: linear-gradient(180deg, #4f4f4f00 0%, #000 100%);
  }
}
