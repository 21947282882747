@use "./tailwind.scss";
@use "./reset.scss";
@use "./sidebar.scss";
@use "./home.scss";
@use "./react-datepicker.scss";
@use "./login.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
body {
  color: white;
  font-weight: 500;
  background: linear-gradient(
    180deg,
    #000 0%,
    rgb(16, 23, 38) 55.73%,
    #000 100%
  );
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
}

// h1 {
//   font-family: "Gemunu Libre", sans-serif;
// }

.tv-layout {
  display: flex;
}
.fs-20 {
  font-size: 20px !important;
}

.resetInput {
  all: unset;
  text-align: center;
  pointer-events: none;
}

div:focus {
  outline: none;
}
.react-datepicker {
  div:focus {
    outline: 2px solid white !important;
  }
}

.booking-main-page {
  .tag {
    border-radius: 16px;

    background: rgba(31, 41, 55, 0.3);
  }
}

.hotel-card-mini {
  img {
    width: 100%;
    object-fit: cover;
  }
}

.hotel-card-poster {
  img {
    width: 100%;

    object-fit: cover;
  }
}
.hotel-list-card {
  border-radius: 20px;
  background: #1f2937;
  padding: 8px;
}

.video-react-control-bar {
  opacity: 0 !important;
}

*::-webkit-scrollbar {
  display: none;
}
