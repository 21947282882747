.sidebar {
  width: 292px;
  transition: 0.3s;
  position: fixed;
  height: 100vh;
  background: linear-gradient(180deg, #1f2937 0%, #394b64 46%, #58759d 100%);
  box-shadow: 2px 0px 21px 0px rgba(0, 0, 0, 0.5);
  z-index: 100;

  .sidebar-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    margin-bottom: 5px;
    font-size: 28px;
    padding: 1rem 25px;

    svg {
      width: 30px;
      height: 30px;
      margin: 0;
    }

    .sidebar-item-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: start;

      p {
        margin-left: 1rem;
        white-space: nowrap;
        font-size: 24px;
        font-weight: 500;
      }
    }

    .sidebar-item-underline {
      width: 100%;
      height: 4px;
      margin-top: 8px;
      background: transparent;
      border-radius: 1px;
    }

    .sidebar-item-underline-active {
      background: #4f46e5;
    }
  }

  .sidebar-item-active {
    // background: linear-gradient(
    //   90deg,
    //   rgba(61, 40, 223, 0.8) 0%,
    //   rgba(61, 40, 223, 0) 100%
    // );

    // backdrop-filter: blur(9.5px);
  }

  .sidebar-content {
    height: 95%;
    margin: 1.5rem 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar-header,
  .sidebar-footer {
    display: flex;
    flex-direction: column;
  }
  p,
  h1 {
    opacity: 1;
  }
}

.sidebar-collapsed {
  width: 96px;
  transition: 0.3s;
  background: linear-gradient(180deg, #0e121d 0%, #0e121d 6.25%, #0e121d 100%);

  .sidebar-item {
    align-items: center;
    justify-content: center;
    svg {
      margin-right: unset;
    }
  }
  p,
  h1 {
    opacity: 0;
    display: none;
  }
}
